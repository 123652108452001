import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../../components/Layout'
import SEO from '../../components/seo'
import RightSide from '../../components/rightside-general'

import BG from '../../images/awards-bg.jpg'

class Superannuation extends React.Component {
	render() {
		const siteTitle = 'Superannuation'
		const siteDescription = this.props.data.site.siteMetadata.description

		return (
			<Layout location={this.props.location} title={siteTitle}>
				<SEO title={siteTitle} description={siteDescription} />
				<div
					id="sliderhead"
					style={{
						background: `url(${BG}) 50% 13px fixed rgb(34, 34, 34`,
						position: 'relative',
					}}
					className="blog-header2"
				/>
				<div className="container">
					<div className="row">
						<div className="col-md-8 left-list">
							<div id="contentgen">
								<h3 className="mb-3">Superannuation</h3>
								<p>
									With the importance of superannuation to provide adequate
									capital for retirement, WSC Group has developed an expertise
									in assisting our clients with their long term superannuation
									strategies. Also, with the complexity of superannuation laws
									WSC Group can provide advice to their clients in the critical
									areas of structuring your superannuation needs and goals.
								</p>
								<p>
									In particular, we have developed a strong expertise in the
									self managed super fund market and have assisted many clients
									in setting up a self managed funds in recent years. Our
									service includes the preparation of financial statements,
									income tax returns, the filing of all regulatory returns and
									completion of all audit requirements for Self Managed
									Superannuation Funds (SMSF). We also assist clients on all tax
									aspects of SMSF including contributions tax, tax planning and
									the establishment and statutory requirements of superannuation
									pensions upon retirement.
								</p>
								<p>
									Our goal is to assist you carefully plan your strategy for
									retirement and maximize the long term wealth that building up
									a self managed super fund allows.
								</p>
								<h4>Our service includes:</h4>
								<ul>
									<li>Establishment of a Self Managed Superannuation Fund</li>
									<li>Advice concerning Compliance Matters</li>
									<li>Advice concerning Taxation Benefits</li>
									<li>Controlling costs of Administration</li>
								</ul>
							</div>
						</div>
						<div className="col-md-4 right-list pull-right">
							<RightSide />
						</div>
					</div>
				</div>
			</Layout>
		)
	}
}

export default Superannuation

export const SuperannuationPageQuery = graphql`
	query SuperannuationPageQuery {
		site {
			siteMetadata {
				title
				author
				description
			}
		}
	}
`
